'use client';

import { type Resource, type i18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { initTranslations } from '@/lib/i18n';

interface TranslationsProviderProps {
	children: React.ReactNode;
	locale: string;
	resources: Resource;
}

export default function TranslationsProvider({ children, locale, resources }: TranslationsProviderProps) {
	const { i18n } = initTranslations(locale, resources);

	return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
