import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/business-web/components/translationsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SharedDataProvider"] */ "/vercel/path0/apps/business-web/context/sharedDataContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/vercel/path0/node_modules/.pnpm/next-auth@5.0.0-beta.25_next@14.2.24_react-dom@18.2.0_react@18.3.1__react@18.3.1__nodemailer@6.10.0_react@18.3.1/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.24_react-dom@18.2.0_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-nunito\",\"weight\":[\"400\",\"500\",\"600\",\"700\",\"900\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"]}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/business-web/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/vercel/path0/node_modules/.pnpm/nuqs@2.4.0_next@14.2.24_react-dom@18.2.0_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["S"] */ "/vercel/path0/node_modules/.pnpm/swr@2.3.2_react@18.2.0/node_modules/swr/dist/index/config-client-Bi4rgVRk.mjs");
