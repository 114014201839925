'use client';

import type { ICountry, ISpecies, ICurrency } from '@aiq/types';
import { createContext, useContext, useEffect, useState } from 'react';
import { getCountries, getSpecies, getCurrencies } from '@aiq/api-client';
import { useApi } from 'lib/useApi';

interface SharedDataContext {
	countries?: ICountry[];
	species?: ISpecies[];
	currencies?: ICurrency[];
}

interface SharedDataProvider {
	children: React.ReactNode;
	auth?: boolean;
}

const SharedDataContext = createContext<SharedDataContext | null>(null);

export function SharedDataProvider({ children, auth = true }: SharedDataProvider) {
	const [countries, setCountries] = useState<ICountry[] | undefined>(undefined);
	const [species, setSpecies] = useState<ISpecies[] | undefined>(undefined);
	const [currencies, setCurrencies] = useState<ICurrency[] | undefined>(undefined);
	const [sharedData, setSharedData] = useState<SharedDataContext>({ countries: undefined, species: undefined });

	const { data: _countries } = useApi<ICountry[]>(getCountries(), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		disableTokenAuthentication: true
	});

	const { data: _species } = useApi<ISpecies[]>(auth ? getSpecies() : null, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false
	});

	const { data: _currencies } = useApi<ICurrency[]>(getCurrencies(), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		disableTokenAuthentication: true
	});

	useEffect(() => {
		setCountries(_countries);
	}, [_countries]);

	useEffect(() => {
		setSpecies(_species);
	}, [_species]);

	useEffect(() => {
		setCurrencies(_currencies);
	}, [_currencies]);

	useEffect(() => {
		setSharedData({ countries, species, currencies });
	}, [countries, species, currencies]);

	return <SharedDataContext.Provider value={sharedData}>{children}</SharedDataContext.Provider>;
}

export function useSharedData() {
	const context = useContext(SharedDataContext);

	if (!context) {
		throw new Error('useSharedData must be used within a SharedDataProvider');
	}

	return context;
}
